'use client';
import { useEffect } from 'react';
import { sendGTMEvent } from '@next/third-parties/google';
import { useSession } from 'next-auth/react';

import { checkUserIsEmployee } from '~/utils/generalUtils';
import { LocaleEnum } from '~/locales/resources';

type Props = {
  locale?: LocaleEnum;
};

export function Tracking({ locale }: Props) {
  const { data: session } = useSession();
  const user = session?.user;

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID && user) {
      const { id, name, email } = user;
      const isUserEmployee = checkUserIsEmployee(email);
      sendGTMEvent({
        event: 'E_onLayoutLoad',
        user_id: id,
        user_name: name,
        user_email: email,
        user_isEmployee: isUserEmployee,
        user_locale: locale,
        ...(isUserEmployee && { traffic_type: 'internal' }),
      });
    }
  }, [user, locale]);

  return null;
}
